.animationFade_In {
  animation: fadeIn ease 0.5s;
  /*animation-iteration-count: 1; /* nombre de fois que l'on veut effectuer l'animation*/
  animation-fill-mode: forwards;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*0%-100% cest du from au to*/

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0; /*invisible*/
  }
  100% {
    opacity: 1; /* tu réapparais progressivment pdt 1s jusqu'à être visible*/
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animationFade_Out {
  animation-duration: 0.5s;
  animation-name: fadeout;
  /* animation: fadeOut ease 8s;
  animation-fill-mode:forwards ;
  -webkit-animation: fadeOut ease 8s;
  -moz-animation: fadeOut ease 8s;
  -o-animation: fadeOut ease 8s;
  -ms-animation: fadeOut ease 8s; */
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* @keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
}

} */

/* Animation Fade in */
.fade-in {
  animation-duration: 2s;
  animation-name: fadein;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Animation Fade in/out Top */
.fade-in-top {
  animation-duration: 500ms;
  animation-name: fadeintop;
}

@keyframes fadeintop {
  from {
    position: relative;
    top: 100px;
    opacity: 0;
  }

  to {
    position: relative;
    top: 0px;
    opacity: 1;
  }
}

.fade-out-top {
  animation-duration: 250ms;
  animation-name: fadeouttop;
}

@keyframes fadeouttop {
  from {
    position: relative;
    top: 0px;
    opacity: 1;
  }

  to {
    position: relative;
    top: -100px;
    opacity: 0;
  }
}

/* Animation Fade in/out Right */

.fade-in-right {
  animation-duration: 500ms;
  animation-name: fadeinright;
}

@keyframes fadeinright {
  from {
    position: relative;
    right: -100px;
    opacity: 0;
  }

  to {
    position: relative;
    right: 0px;
    opacity: 1;
  }
}
.fade-out-right {
  animation-duration: 500ms;
  animation-name: fadeoutright;
}

@keyframes fadeoutright {
  from {
    position: relative;
    right: 0px;
    opacity: 1;
  }

  to {
    position: relative;
    right: -100px;
    opacity: 0;
  }
}
/* Animation Fade in/out Left */

.fade-in-left {
  animation-duration: 500ms;
  animation-name: fadeinleft;
}

@keyframes fadeinleft {
  from {
    position: relative;
    left: -100px;
    opacity: 0;
  }

  to {
    position: relative;
    left: 0px;
    opacity: 1;
  }
}
.fade-out-left {
  animation-duration: 500ms;
  animation-name: fadeoutleft;
}

@keyframes fadeoutleft {
  from {
    position: relative;
    left: 0px;
    opacity: 1;
  }

  to {
    position: relative;
    left: -100px;
    opacity: 0;
  }
}

/* Glow */
.glow-logo :hover {
  filter: saturate(200%);
  transition: filter 100ms ease-in-out;
}

.glow {
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.glow:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
    0 0 20px rgba(255, 255, 255, 0.3);
}

/* Grow */

.grow {
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);

  
}

img.grow:focus, img.grow:active {
  transform: scale(0.9);

}
.grow:hover {
  transform: scale(1.1);
}


/* effect-shine */
.effect-shine:hover {
  mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  mask-size: 200%;
  animation: shine 2s infinite;
}

@keyframes shine {
  from {
    mask-position: 150%;
  }

  to {
    mask-position: -50%;
  }
}

/* mask txt hover */

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  overflow: hidden;
}
.link:hover .link__mask {
  transform: translate3d(0, 0, 0);
}
.link:hover .link__mask:after {
  transform: translate3d(0, 0, 0);
}

.link__text {
  color: #fff;
}

.link__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  transform-origin: left;
  overflow: hidden;
}
.link__mask:after {
  content: attr(data-text);
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-orange);
  transition: inherit;
  transform: translate3d(100%, 0, 0);
  text-shadow: 0 0 10px;
}

/* mask txt hover white */

.link:hover .link__mask-white {
  transform: translate3d(0, 0, 0);
}
.link:hover .link__mask-white:after {
  transform: translate3d(0, 0, 0);
}

.link__mask-white {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: translate3d(-100%, 0, 0);
  transform-origin: left;
  overflow: hidden;
}

.link__mask-white:after {
  content: attr(data-text);
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: inherit;
  transform: translate3d(100%, 0, 0);
  text-shadow: 0 0 5px var(--color-orange);
}

.link__mask-white.span-h2 {
  font-size: 60px;
  font-family: "Bebas Neue" !important;
}

@media (min-width: 1201px) {
  .link__text {
    line-height: 1.2;
  }
}
@media (max-width: 1200px) {
  .link__mask-white.span-h2,
  .link__text {
    font-size: 50px !important;
  }

  .link__text {
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .link__mask-white.span-h2,
  .link__text {
    font-size: 40px !important;
  }
}
@media (max-width: 576px) {
  .link__mask-white.span-h2,
  .link__text {
    font-size: 30px !important;
  }
}
@media (max-width: 500px) {
  .link__mask-white.span-h2,
  .link__text {
    font-size: 20px !important;
  }
}

/* Reveal effects */

.fade-in-front {
  animation-name: fadeInFront;
  animation-duration: 500ms;
}

@keyframes fadeInFront {
  from {
    opacity: 0;
    scale: 5;
    filter: blur(50px);
  }

  to {
    opacity: 1;
    scale: 1;
    filter: blur(0);
  }
}
.fade-out-front {
  animation-name: fadeOutFront;
  animation-duration: 500ms;
}

@keyframes fadeOutFront {
  from {
    opacity: 1;
    scale: 1;
    filter: blur(0);
  }

  to {
    opacity: 0;
    scale: 5;
    filter: blur(50px);
  }
}

.primary-glow:hover {
  box-shadow: rgb(255, 195, 0, 0.5) 0px 7px 29px 0px;
  transition: all 100ms ease-in-out;
}

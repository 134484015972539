/* Basic container h-100 overflowHidden */

div {
  &.overflow-hidden {
    overflow-y: hidden;
    min-height: 100vh;
  }

  &#square {
    border: 2px solid var(--color-grey-light);
    border-radius: 3px;
    padding: 0px;
    width: 20px;
    height: 20px;

    &.green {
      background-color: var(--color-green);
      border: 2px solid var(--color-grey-dark);
    }
  }
}
/* Container Correction */

p {
  white-space: pre-line !important;
}
.container_correction {
  border-radius: 10px;
  border: 0px;

  &.green {
    background-color: #8ac9264d;
  }

  &.red {
    background-color: #ff595f77;
  }

  &.yellow {
    background-color: #ffca3a69;
  }
}

.stat_rounded_container {
  border: solid 2px var(--color-grey-light);
  border-radius: 10px;
}

.grey_container {
  border-radius: 10px;
  background: var(--color-grey-light);
  min-width: 100px;
  min-height: 60px;

  width: 100%;
  height: 100%;
  overflow: hidden;

  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.container_border {
  border: solid 1px var(--color-grey-dark);
}

.drop-container {
  margin: 0;
  width: 100%;
  border: 4px dashed var(--color-grey-dark);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

.drop-container:hover {
  background-color: var(--color-grey-dark);
}

/* demo editTemplate */

.template_render {
  width: 90%;
  border-radius: 10px;
  border: solid 2px;
  border-color: var(--color-grey-dark);
  touch-action: manipulation;
}

.template_selecter {
  width: 20%;
  border-radius: 5px;
  touch-action: manipulation;
  transition: box-shadow 100ms ease-in-out;
  border: solid 1px var(--color-grey-light);
}

.template_selecter:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1pt var(--color-grey-dark);
}

.modal-dialog {
  max-width: 90%;
}

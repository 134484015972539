/* Card Cours */

.card-deck {
  overflow: hidden;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 0 !important;
}

@media (min-width: 501px) {
  .card-title {
    font-size: 1.5rem;
  }

  .card-deck {
    margin-left: 5rem;
    margin-right: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    margin: 0rem;
  }
  #card-lesson {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin: 0rem;
  }
}
@media (max-width: 500px) {
  .card-title {
    font-size: 0.75rem;
  }

  .card-deck {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  #card-lesson {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0px;
  }
}

.card-custom {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  width: 225px;
  height: 250px;
  border-radius: 15px;
  justify-content: space-evenly;
  border: 2px solid var(--color-grey-light);
  // box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  margin-bottom: 0 !important;

  div {
    img {
      width: 80px;
    }
  }
}

@media (min-width: 501px) {
}
@media (max-width: 500px) {
  .card-custom {
    width: 140px;
    height: 140px;

    div {
      img {
        width: 40px;
      }
    }
  }
}

/*
@media (min-width: 320px) {
  .card-custom {
    width: auto;
    height: auto;
  }
  .card-deck {
    margin: 0 5vw 0;
  }
}
@media (min-width: 620px) {
  .card-custom {
    width: 270px;
    height: 430px;
  }
  .card-deck {
    margin: 0 10vw 0;
  }
}
@media (min-width: 800px) {
  .card-custom {
    width: 350px;
    height: 450px;
  }
  .card-deck {
    margin: 0 10vw 0;
  }
}
*/

.card-custom-img {
  height: 200px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-color: inherit;
}

/* First border-left-width setting is a fallback */
.card-custom-img::after {
  position: absolute;
  content: "";
  top: 161px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-top-width: 40px;
  border-right-width: 0;
  border-bottom-width: 0;
  border-left-width: 545px;
  border-left-width: calc(575px - 5vw);
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: inherit;
}

.card-custom-avatar img {
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  position: absolute;
  top: 100px;
  left: 1.25rem;
  width: 100px;
  height: 100px;
}

.card-footer {
  background: inherit;
  border-color: inherit;
}

/* Card Landing */
.card_landing {
  display: flex;
  background-color: #f6f6f6;
  width: 224px;
  height: 277px;
}
.module-border-wrap {
  width: 250px;
  height: 341px;

  padding: 1rem;
  position: relative;

  border-radius: 11px;
  padding: 3px;
}

.module {
  border-radius: 10px;
  height: 335px;

  background: #f6f6f6;

  padding: 2rem;
}

.gradient_orange {
  background: linear-gradient(
    to right,
    var(--color-orange),
    var(--color-yellow)
  );
}

.gradient_blue {
  background: linear-gradient(
    to right,
    var(--color-blue),
    var(--color-blue-light)
  );
}

.gradient_green {
  background: linear-gradient(
    to right,
    var(--color-green),
    var(--color-green-acid)
  );
}

.image_card {
  width: 100px;
  height: 100px;
}

.card_title {
  font-weight: 800;
  font-size: 20px;
}

.forum_card {
  margin-top: 5vw;
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 15px;
  background-color: #f5f5f5ba;
  justify-content: space-evenly;
  box-shadow: 0 0 15px rgba(10, 10, 10, 0.3);
  border-bottom: solid;
  border-top: solid;
  border-bottom-color: var(--color-blue-light);
  border-top-color: var(--color-blue-light);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.forum_card:hover {
  cursor: pointer;
  border-bottom-color: var(--color-yellow);
  border-top-color: var(--color-yellow);
}

.forum_card_content {
  margin-left: 1vw;
}

.forum_card_title {
  margin-bottom: 6px;
}

.post_card {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 150px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #f5f5f5ba;
  justify-content: space-evenly;
  border: solid 2px #d9d9d9;
  overflow: auto;
}

.post_card_content {
  margin-left: 1vw;
  margin-right: 1vw;
}

.post_card_title {
  margin-bottom: 6px;
}

.post_content {
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #f5f5f5ba;
  justify-content: space-evenly;
  border: solid 2px #d9d9d9;
  margin-top: -2px;
  margin-bottom: -1px;
}

/* Deck */
.module_deck {
  border-radius: 10px;
  height: 130px;
  width: 300px;

  background: #eceeef;

  padding: 2rem;
  color: black;
  /* btn part */
  vertical-align: middle;
  white-space: nowrap;
  transform: translateZ(0);
  transition: filter 0.2s;
  cursor: pointer;
  touch-action: manipulation;

  overflow: hidden;
  text-overflow: ellipsis;
}

.module-border-wrap-deck {
  height: 136px;
  width: 306px;

  position: relative;

  border-radius: 11px;
  padding: 3px;
}

.card_title.row {
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.gradient_orange_hover:hover {
  background: linear-gradient(
    to right,
    var(--color-orange),
    var(--color-yellow)
  );
}

.gradient_blue_hover {
  background-color: rgba(101, 101, 101, 0);

  transition: background-color 200ms;
}
.gradient_blue_hover:hover {
  background-color: var(--color-blue);
  background-image: linear-gradient(
    to right,
    var(--color-blue),
    var(--color-blue-light)
  );
  transition: background-color 200ms;
}

.gradient_green_hover:hover {
  background: linear-gradient(
    to right,
    var(--color-green),
    var(--color-green-acid)
  );
}

/* Memory Card */

.module_memoryCard {
  border-radius: 10px;
  width: 560px;
  height: 340px;

  background: #eceeef;

  padding: 2rem;
  color: black;
  /* btn part */
  vertical-align: middle;
  white-space: nowrap;
  transform: translateZ(0);
  transition: filter 0.2s;
  cursor: pointer;
  touch-action: manipulation;

  perspective: 1400px;

  animation: cardFadeIn 2.5s;
}

.module_memoryCard::after {
  box-shadow: 5px 5px 5px #eceeef;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.module_memoryCard:hover::after {
  opacity: 1;
}

/* Codepen Pen Status */
@keyframes penStatusFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.pen-status {
  /* position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px; */

  /*   font-weight: bolder; */
  color: #333;
  animation: penStatusFadeIn 5s;
}

@keyframes cardFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.memoryCard-container {
  border-radius: 10px;
  width: 448px;
  height: 272px;

  perspective: 1400px;
  animation: cardFadeIn 2.5s;
}

.memoryCard {
  border-radius: 10px;
  width: 448px;
  height: 272px;

  transform-style: preserve-3d;
  cursor: pointer;
}

.memoryCard-front,
.memoryCard-back {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
}

.memoryCard-front {
  background: #eceeef;
}

.memoryCard-back {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);

  background: #eceeef;
}

p {
  white-space: normal;
  overflow-wrap: break-word;
  width: 100%;
  overflow: hidden;
}

.logo_landing {
  background: url('https://firebasestorage.googleapis.com/v0/b/schoolupgreat-42876.appspot.com/o/Images%20Styles%2FLogoBleuSimple.png?alt=media');
  background-repeat: no-repeat;
  background-size: 288px;

  width: 288px;
  height: 352px;
  z-index: auto;
}


img {
  max-width: 100%;
  display: block;
}

@media screen and (max-width: 375px) {
  img {
    max-width: 80%;
    display: block;
  }
}

.image_landing {
  min-height: 500px;
  min-width: 200px;
}


/* .responsive_marging{

  margin-bottom: 5;
  margin-top: 2;
}

@media (max-width: 767px) {
  .responsive_marging {
    margin-top: 3;
    margin-bottom: 1;
  }
  
} */

/*  @media screen and (max-width: 769px) {
  .image_landing {
    height: 400px;
  }
  .logo_landing {
    background-size: 240px;
    width: 240px;
  }
}

@media screen and (max-width: 375px) {
  .image_landing {
    height: 400px;
  }
  .logo_landing {
    background-size: 240px;
    width: 240px;
  }
} */

.post_profile {
  width: 10vw;
  height: 10vw;
}

/* SVG Animation */

/* Grow */

.grow {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grow:hover {
  transform: scale(1.1);
}

/* Grow big */

.grow_big {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grow_big:hover {
  transform: scale(1.5);
}

/* Glow */
.glow {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.glow:hover {
  box-shadow: 0 0 8px grey;
}

/* Hover Shadow */
@keyframes hover {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-6px);
  }
}
@keyframes hover-shadow {
  0% {
    transform: translateY(6px);
    opacity: 0.4;
  }
  50% {
    transform: translateY(3px);
    opacity: 1;
  }
  100% {
    transform: translateY(6px);
    opacity: 0.4;
  }
}
.hover-shadow {
  display: inline-block;
  position: relative;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hover-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  /* W3C */
  transition-duration: 0.3s;
  transition-property: transform opacity;
}
.hover-shadow:hover {
  transform: translateY(-6px);
  animation-name: hover;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.hover-shadow:hover:before {
  opacity: 0.4;
  transform: translateY(6px);
  animation-name: hover-shadow;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* Float */
.float {
  display: inline-block;
  transition-duration: 0.3s;
  transition-property: transform;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.float:hover {
  transform: translateY(-5px);
}

/* Drag and Drop Images */

.drag-and-drop-item {
  cursor: grab;
}

/* Effect Box Shadow */
.box_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.create_qcm_image {
  top: 1px;
  bottom: 1px;
  height: 150px;
  width: auto;
}
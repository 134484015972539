/* Color Variables */

:root {
  --color-red: #ff595e;
  --color-orange: #ff924c;
  --color-yellow: #ffca3a;
  --color-green-acid: #c5ca30;
  --color-green: #8ac926;
  --color-green-dark: #52a675;
  --color-blue-light: #19bdc4;
  --color-blue: #1982c4;
  --color-blue-navy: #4267ac;
  --color-purple: #6a4c93;
  --color-grey-light: #eceeef;
  --color-grey-dark: #707070;
}

/* .App {
  text-align: center;
} */

/* Animation entrante */
.fade-in {
  animation-duration: 2s;
  animation-name: fadein;
}
.fade-in-top {
  animation-duration: 2s;
  animation-name: fadeintop;
}
.fade-in-left {
  animation-duration: 2s;
  animation-name: fadeinleft;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeintop {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}
@keyframes fadeinleft {
  from {
    left: -50px;
    opacity: 0;
  }

  to {
    left: 0px;
    opacity: 1;
  }
}
/* Lucas : Le CSS qui suit est le vieux css */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}

.activate {
  color: #fff;
}
.activate:hover {
  color: #fff;
  text-decoration: none;
}

.noRadio input[type="radio"] {
  display: none;
}

.Star {
  cursor: pointer;
  transition: color 200ms;
}

/* yellow : #ffc107
grey: #e4e5e9 */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Text in parent */
.overflow-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  box-sizing: content-box;
}

.centeredTextOnImage {
  width: 110%;
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -40%);
}

.titleGradient {
  font-size: 48px;
  background: -webkit-linear-gradient(45deg, #3d50e9, rgba(99, 187, 208, 1));
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
}

.text-gradient {
  /* background: -webkit-linear-gradient(45deg, #3d50e9, rgba(99, 187, 208, 1)); */
  background: rgb(61, 80, 233);
  background: linear-gradient(
    90deg,
    rgba(61, 80, 233, 1) 22%,
    rgba(81, 198, 220, 1) 50%,
    rgba(99, 187, 208, 1) 79%
  );

  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

/* Personal responsive design */
.responsive-width-profile {
  width: 80%;
}

@media screen and (max-width: 1280px) {
  .responsive-width-profile {
    width: 100%;
  }
}

@media screen and (max-width: 1030x) {
  .card-columns {
    column-count: 2;
  }
}

@media screen and (max-width: 760px) {
  .card-columns {
    column-count: 1;
  }
}

.container_height {
  min-height: 1000px;
}

/* Animation Loading */

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/* Layouts */

#layout_body {
  display: flex;
  flex-direction: column;
}

#layout_content {
  flex: 1 0 auto;
  padding: 10px;
}

#layout_footer {
  flex-shrink: 0;
}

.input-group-text {
  border-radius: 10px;
}

.form-control {
  border-radius: 10px;
}

.form-control:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 0 rgb(0 123 255 / 25%) !important;
}

.input_correct {
  border: solid 2px var(--color-green) !important;
  transition: border 0.3s ease-in-out;
}

.input_false {
  border: solid 2px var(--color-red) !important;
  transition: border 0.3s ease-in-out;
}

.input_grey {
  border: 1px solid #ced4da;
  transition: border 0.3s ease-in-out;
}

.navbar_module {
  height: 2px;
  background: linear-gradient(
    to right,
    var(--color-blue),
    var(--color-blue-light)
  );
}

.navbar {
  background: white;
}

.show.navbar-collapse {
  border-radius: 15px;
  z-index: 5;
  border: solid;
  border-width: 2px;
  border-color: var(--color-blue);
  background-color: white;
  padding: 5px;

  margin-top: 8px;
  opacity: 100%;
}

/* Bootstrap navbar btn Toggle */

button:focus {
  outline: 0px auto var(--color-blue-light);
}

.px-4-sm-0 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-width: 769px) {
  .px-4-sm-0 {
    padding-left: 1 rem;
    padding-right: 1 rem;
  }

  .px-5-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding-right: 15px !important;
    padding-left: 15px !important;

    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

.px-5-xs-0 {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 500px) {
  .px-5-xs-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}
.py-5-xs-0 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (max-width: 500px) {
  .py-5-xs-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
/* Btn primary 1️⃣ */

.btn_primary,
.btn-primary {
  /* Text */
  font-size: 15px;
  letter-spacing: 1px;
  color: white;
  text-align: center;
  min-height: 40px;
  min-width: 175px;
  /* Btn style */
  border-width: 0 0 3px;
  /* border-top-left-radius: 14px;
  border-top-right-radius: 14px; */
  border-radius: 14px;
  padding: 5px 16px;
  line-height: 20px;
  display: inline-block;

  vertical-align: middle;
  white-space: nowrap;
  border-style: solid;
  transform: translateZ(0);
  transition: filter 0.2s;
  cursor: pointer;

  touch-action: manipulation;
}

.btn_primary:disabled,
.btn-primary:disabled {
  color: var(--color-grey-dark);
  background-color: var(--color-grey-light);
  border-color: transparent;
}

@media screen and (max-width: 992) {
  .btn_primary,
  .btn-primary {
    min-width: 150px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 426px) {
  .btn_primary,
  .btn-primary {
    margin-bottom: 1rem;
  }
}

.btn_primary:hover,
.btn-primary:hover {
  filter: brightness(1.1);
}
.btn_primary:disabled,
.btn-primary:disabled {
  filter: brightness(1);
  cursor: initial;
}

.btn_primary:active,
.btn-primary:active {
  border-width: 0 0 0;
  /* margin-bottom: 3px; */
  transform: translateY(3px);
}

.btn_primary:focus,
.btn-primary:focus {
  outline: 0 !important;
}

.w_200 {
  width: 200px !important;
}

button.btn_primary.disabled,
button.btn-primary.disabled {
  background-color: var(--color-grey-light);
  border: 0px;
  cursor: initial;
  color: grey;
}

button.btn_primary.disabled:hover,
button.btn-primary.disabled:hover {
  filter: brightness(1);
}

button.btn_primary.invisible.disabled,
button.btn-primary.invisible.disabled {
  opacity: 0;
}
button,
svg {
  &.hover:hover {
    filter: brightness(0.9);
    transition: filter 200ms ease-in-out;
  }
  &.red {
    background-color: var(--color-red);
    border-color: rgb(192 0 0);
  }

  &.orange {
    background-color: var(--color-yellow);
    border-color: #ff924c;
  }

  &.green-acid {
    background-color: var(--color-green-acid);
    border-color: #6b6e1b;
  }

  &.green {
    background-color: var(--color-green);
    border-color: #649516;
  }
  &.purple {
    background-color: var(--color-purple);
    border-color: rgb(49, 33, 71);
  }

  &.blue-light {
    background-color: var(--color-blue-light);
    border-color: var(--color-blue);
  }

  &.blue {
    background-color: var(--color-blue);
    border-color: var(--color-blue-navy);
  }

  &.blue-navy {
    background-color: var(--color-blue-navy);
    border-color: #213d72;
  }
}
/* Btn Secondary 2️⃣ */
.btn_secondary,
.btn-secondary {
  /* Text */
  font-size: 15px;
  letter-spacing: 1px;
  color: var(--color-grey-dark);
  text-align: center;
  height: 40px;
  /* width: 40px; */

  /* Btn style */
  border-width: 1px 1px 3px;
  /* border-top-left-radius: 14px;
  border-top-right-radius: 14px; */
  border-radius: 10px;
  /* padding: 5px 16px; */
  line-height: 20px;
  display: inline-block;

  vertical-align: middle;
  white-space: nowrap;
  border-style: solid;
  transform: translateZ(0);
  background-color: transparent !important;
  cursor: pointer;
  touch-action: manipulation;

  /* Si le texte dépasse */
  overflow: hidden;
  text-overflow: ellipsis;

  outline: none;

  filter: brightness(1);
  transition: filter 0.2s;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.btn_secondary:hover,
.btn-secondary:hover {
  background-color: var(--color-grey-light) !important;
  color: var(--color-grey-dark) !important;
  transition: 100ms all ease-in-out;
}

.btn:focus {
  box-shadow: none !important;
}
.btn:active {
  box-shadow: none !important;
}

.btn:disabled {
  cursor: initial;
}
.btn_secondary:active,
.btn-secondary:active {
  border-width: 1px 1px 1px;
  transform: translateY(1px);
}

.btn_secondary:disabled,
.btn-secondary:disabled {
  opacity: 0.5;
  color: #121212 !important;
  cursor: initial;
}

.btn_secondary:hover:disabled,
.btn-secondary:hover:disabled {
  opacity: 0.5;
  filter: brightness(1);
}

.position_fixed {
  position: fixed;
}

.btn_secondary:focus,
.btn-secondary:focus {
  outline: 0 !important;
}

.progressBar {
  width: max-content !important;
}

/* Btn QCM */

.btn_qcm {
  /* Text */
  font-size: 15px;
  letter-spacing: 1px;
  color: #121212;

  background-color: #f7f7f7;
  text-align: center;

  /* Btn style */
  border-width: 2px;
  border-style: solid;
  border-color: var(--color-grey-light);

  //  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: none;

  /* border-top-left-radius: 14px;
  border-top-right-radius: 14px; */
  border-radius: 15px;
  padding: 5px;
  margin: 5px;
  line-height: 20px;
  display: inline-block;

  vertical-align: middle;

  transform: translateZ(0);
  transition: filter 0.5s, color 0.3s, outline 0.3s;
  cursor: pointer;
  touch-action: manipulation;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;

  outline: none;

  filter: brightness(1);
  transition: filter 200ms ease-in-out;
}

.btn_qcm:disabled {
  cursor: default;
}

.breakAll {
  word-break: break-all;
}

@media screen and (min-width: 768px) {
  .btn_qcm {
    min-height: 100px;
    min-width: 310px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .btn_qcm {
    min-height: 80px;
    min-width: 160px;
    width: 100%;
  }
  .progressBar {
    width: 300px !important;
  }
}

.btn_qcm:hover {
  filter: brightness(0.8);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.btn_qcm:active {
  transform: translateY(1px);
}

.btn_qcm.selected {
  box-shadow: 0 0 0px 4px var(--color-blue-light);
  transition: box-shadow 0.3s, color 0.3s;
  color: var(--color-blue-light);
}

.btn_qcm.not_selected {
  box-shadow: 0 0 0px 0px var(--color-blue-light);
  transition: box-shadow 0.3s, color 0.3s;
  color: black;
}

.btn_qcm.selected_correct {
  box-shadow: 0 0 0px 4px var(--color-green);
  transition: box-shadow 0.3s, color 0.3s;
  color: var(--color-green);
}

.btn_qcm.not_selected_correct {
  box-shadow: 0 0 0px 4px var(--color-green);
  transition: box-shadow 0.3s, color 0.3s;
  color: black;
}

.btn_qcm.selected_false {
  box-shadow: 0 0 0px 4px var(--color-red);
  transition: box-shadow 0.3s, color 0.3s;
  color: var(--color-blue-light);
}

.btn_qcm.not_selected_false {
  box-shadow: 0 0 0px 0px var(--color-red);
  transition: box-shadow 0.3s, color 0.3s;
  color: black;
}

/* End Btn */
.btn-login {
  border-radius: 10px !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  touch-action: manipulation;
}

.btn-login:focus {
  outline: 0 !important;
}

@media screen and (max-width: 800px) {
  .btn-login {
    width: 80% !important;
  }
}

.btn-empty,
.btn_empty {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.5715;
  background-color: transparent;
  background-image: none;
  border: none;
}

/* Round button */
.btn_round {
  width: 60px;
  height: 60px;
  line-height: 60px;
  // margin-left: 6px;
  // margin-right: 6px;
  border-radius: 100%;
  background-color: #33353d;
  border-width: 0 0 0;
}

.btns_next {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.btns_next {
  margin-right: 15px;
  text-transform: uppercase;
  color: var(--color-grey-dark);

  font-weight: 700;
  font-size: 13px;
}
.btn_round {
  background-color: var(--color-grey-light);
  color: var(--color-grey-dark);
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

/* .btn_round:active,
.btn_round:focus,
.btn_round:hover {
  color: #fff;
  background-color: #29aafe;
} */
.btn_round:focus {
  outline: 0;
}
.btn_round {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.btn_round:disabled:hover {
  background-color: transparent !important;
}

.btn_round:disabled {
  color: transparent !important;
}

.btn_round.green:hover {
  background-color: var(--color-green);
  color: white;
}
.btn_round.yellow:hover {
  background-color: var(--color-yellow);
  color: white;
}
.btn_round.red:hover {
  background-color: var(--color-red);
  color: white;
}
.btn_round.blue:hover {
  background-color: var(--color-blue);
  color: white;
}

.btn_round.grey {
  background-color: transparent;
}

.btn_round.grey:hover {
  background-color: var(--color-grey-light);
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
  color: white;
}

/* Profile Image update*/

.img_profile_update {
  cursor: pointer;
  touch-action: manipulation;
  transition: all 0.3s linear;
}

.img_profile_update:hover {
  transition: all 0.3s linear;
  filter: brightness(0.5);
}

.img_update {
  transition: all 0.3s linear;
}

.img_update.unlocked {
  cursor: pointer;
  touch-action: manipulation;
}

.img_update.unlocked:hover {
  filter: brightness(0.5);
  transition: all 0.3s;
}

.locked {
  opacity: 0.4;
}

.img_update .unlocked:hover {
  filter: brightness(0.5);
  transition: all 0.3s;
}

/* btn svg ⭐️ */
.btn_svg {
  opacity: 0.3;
  width: 50px;
  transition: all 0.2s;
  cursor: pointer;

  touch-action: manipulation;
}

.btn_svg:hover {
  opacity: 1;
  transform: (1.5);
}

.btn_svg.active {
  opacity: 1;
  transform: scale(1.5);
}

/* btn svg up ⬆ */
.btn_svg_updown {
  opacity: 0.3;
  width: 50px;
  transition: all 0.2s;
  cursor: pointer;
  filter: grayscale(100%);
  --value: 100%;

  --value: 0%;

  touch-action: manipulation;
}

.btn_svg_updown:hover {
  opacity: 1;
  filter: grayscale(0);
  --value: 0%;
}

.btn_svg_updown.active {
  opacity: 1;
  filter: grayscale(0);

  --value: 0%;
}

/* btn svg ❌ */
.svg_grey {
  fill: var(--color-grey-dark);
  stroke-width: 3;
}

.btn_svg_cross:active {
  fill: var(--color-red);
}

.btn_svg_eye:active {
  fill: var(--color-blue-light);
}

.btn_svg_download:active {
  fill: var(--color-green);
}

/* Bootstrap list-group modified */
.list-group-item.active {
  background-color: var(--color-blue);
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.1rem 0.2rem;

  height: 60px;
  max-width: 175px;

  margin: 0px 5px;
  border-radius: 10px !important;
}

.card .card-hearder {
  border-radius: 20px !important;
}

/* Bootstrap Modal Btn close */

.buttton.close:focus {
  outline: 0;
}

/* Btn Exo */

.btn-exo,
.btn_exo {
  /* Text */
  color: black;

  border-radius: 10px;
  /* Btn style */
  border: none;
  border-width: 0px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  gap: 20px;

  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;

  border-style: solid;
  transform: translateZ(0);
  transition: filter 0.2s;
  cursor: pointer;
  touch-action: manipulation;
  overflow: hidden;
  text-overflow: ellipsis;

  outline: none;

  width: 100%;

  background-color: white;

  &.active {
    background-color: var(--color-grey-light) !important;
  }
}

.breakAll {
  word-break: break-all;
}

.btn-exo:hover:not(:disabled),
.btn_exo:hover:not(:disabled) {
  background-color: var(--color-grey-light);
  transition: background-color 100ms ease-in-out;
}

.btn_exo .selected {
  border-color: var(--color-blue-light);
  border-width: 2px;
  transition: border-color 0.3s, border-width 0.3s;
}

.img_wrapper {
  position: absolute;
}

.btn-responsive {
  /* matches 'btn-md' */
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

@media (max-width: 760px) {
  /* matches 'btn-xs' */
  .btn-responsive {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}

//Btn SVG
.btn {
  &.svg-grey {
    color: var(--color-grey-light);
  }
  &.svg-grey:hover {
    color: var(--color-grey-dark);
    transition: color 100ms ease-in-out;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

input [type="radio"] {
  display: none;
}

.Star {
  cursor: pointer;
  transition: color 200ms;
}

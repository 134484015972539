.position_center {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.gap-20 {
  gap: 20px;
}
.mx_1 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
@media screen and (max-width: 1024px) {
  .mx_1 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
}

@media screen and (max-width: 962px) {
  .mx_1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
}

/* Grid */

.basic-grid {
  width: "100%";
  display: grid;
  gap: 1rem;
  /* grid-template-columns: repeat(auto-fill, minmax(50px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(80px, 100px));
}

.image_column_60 {
  flex-direction: column;
  width: 60px;
  height: 60px;
}

.responsive_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 992px) {
  .responsive_row {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
  }
}

/* Font */

@font-face {
  font-display: swap;
  font-family: "Regular";
  src: url("../styles/font/10xTTF/CiutadellaRounded.ttf") format("truetype");
}

/* @font-face {
  font-family: 'Bold';
  src: url('../styles/font/10xTTF/CiutadellaRoundedBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Light';
  src: url('../styles/font/10xTTF/CiutadellaRoundedLight.ttf') format('truetype');
} */

@font-face {
  font-display: swap;
  font-family: "Medium";
  src: url("../styles/font/10xTTF/CiutadellaRoundedMedium.ttf")
    format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: url("../styles/font/10xTTF/CiutadellaRoundedSemiBold.ttf")
    format("truetype");
}

.bold {
  font-family: "SemiBold";
}

body {
  font-display: swap;
  font-family: "Regular" !important;
}

h1,
h2,
h3 {
  font-family: "Medium" !important;
}

.katex {
  font-family: "Regular" !important;
}

.underline {
  text-decoration: underline;
}

.gradiant-title {
  /* Gradient title */
  background: -webkit-linear-gradient(
    left,
    var(--color-blue-light),
    var(--color-blue-navy)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* End Gradient Title */

/* Title Apprendre Efficacement Gratuitement Landing Student*/
h1 {
  font-size: 90px;
}

.text-bloc {
  margin-left: 3rem;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 60px;
  }
  .text-bloc {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 430px) {
  h1 {
    font-size: 40px;
  }
  .text-bloc {
    margin-left: 0rem;
  }
}

/* End Title Apprendre Efficacement Gratuitement Landing Student*/

/* h2 */

h2 {
  font-size: 70px;
}

@media screen and (max-width: 769px) {
  h2 {
    font-size: 40px;
  }
}

/* End h2 */

/* h3 */

h3 {
  font-size: 50px;
}

@media screen and (max-width: 769px) {
  h3 {
    font-size: 30px;
  }
}

/* End h3 */

.text-hover:hover {
  color: var(--color-blue) !important;

  -webkit-transition: color 2s ease-out;
  -moz-transition: color 2s ease-out;
  -o-transition: color 2s ease-out;
  transition: color 2s ease-out;
}

.card_header {
  color: var(--color-green);
  font-size: 20px;
  font-weight: bold;
}

.card_body {
  font-size: 20px;
}

.text_correct {
  color: var(--color-green);
}

.text_false {
  color: var(--color-red);
}

.text_notanswered {
  color: var(--color-orange);
}

.card_footer {
  color: rgb(79, 79, 79);

  font-size: 20px;
}

.fr-emoticon-img {
  background-repeat: no-repeat !important;
  font-size: inherit;
  height: 1em;
  width: 1em;
  min-height: 20px;
  min-width: 20px;
  display: inline-block;
  margin: -0.1em 0.1em 0.1em;
  line-height: 1;
  vertical-align: middle;
}

.forum_msg_1 {
  margin-left: 1vw;
}

.forum_msg_2 {
  overflow: auto;
  height: 100%;
  margin-left: 2vw;
  padding-right: 15px;
  padding-left: 15px;
}

a:hover {
  text-decoration: none;
}

a:has(> .grey):hover {
  filter: brightness(50%);
  transition: all 200ms ease-in-out;
}

.min-content {
  width: min-content;
}

.max-content {
  width: max-content;
}

svg,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  &.red {
    color: var(--color-red);
  }

  &.orange {
    color: var(--color-yellow);
  }

  &.green-acid {
    color: var(--color-green-acid);
  }

  &.green {
    color: var(--color-green);
  }
  &.purple {
    color: var(--color-purple);
  }

  &.blue-light {
    color: var(--color-blue-light);
  }

  &.blue {
    color: var(--color-blue);
  }

  &.blue-navy {
    color: var(--color-blue-navy);
  }
  &.grey-dark,
  .grey {
    color: var(--color-grey-dark);
  }
  &.grey-light {
    color: var(--color-grey-light);
  }
}

.animation-point {
  animation-name: animation-point;
  animation-duration: 2s;
  opacity: 0;
  transform: translateY(0);
}

@keyframes animation-point {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
